import { Link, Outlet, useLocation } from "react-router-dom";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { useMe } from "../hooks/useResults";

const AdminLayout = () => {
  const location = useLocation();
  const { logout } = useMe();

  const handleSignOut = async () => {
    logout();
  };

  const navItems = [
    { path: "/admin", label: "Overview" },
    { path: "/admin/manage", label: "Manage" },
  ];

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "grey.100" }}>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "primary", top: 0, zIndex: 1100 }}
      >
        <Container maxWidth={false} sx={{ height: 64 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  src="/images/lacy-dog-square.jpg"
                  alt="logo"
                  style={{
                    display: "block",
                    height: "50px",
                    width: "auto",
                    borderRadius: "50%",
                  }}
                />
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  noWrap
                  color="secondary"
                  textTransform="uppercase"
                  letterSpacing={2}
                >
                  Scout Admin
                </Typography>
              </Link>
            </Stack>

            <Stack direction="row" spacing={3} alignItems="center">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color:
                        location.pathname === item.path
                          ? "primary.main"
                          : "white",
                      bgcolor:
                        location.pathname === item.path
                          ? "primary.light"
                          : "transparent",
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                      fontSize: 16,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      letterSpacing: 1,
                      "&:hover": {
                        color: "primary.main",
                        bgcolor: "white",
                        transition:
                          "background-color 0.3s ease-out, color 0.3s ease-out",
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Link>
              ))}
              <Button
                onClick={handleSignOut}
                sx={{
                  color: "white",
                  bgcolor: "transparent",
                  px: 2,
                  py: 1,
                  borderRadius: 1,
                  fontSize: 16,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  letterSpacing: 1,
                  "&:hover": {
                    color: "primary.main",
                    bgcolor: "white",
                    transition:
                      "background-color 0.3s ease-out, color 0.3s ease-out",
                  },
                }}
              >
                Sign Out
              </Button>
            </Stack>
          </Stack>
        </Container>
      </AppBar>

      <Box
        sx={{
          minHeight: "calc(100vh - 96px)",
          position: "relative",
          p: 2,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
