import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Checkbox,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { fetcher } from "../../utils/fetchData";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tag } from "@mui/icons-material";
import { debounce } from "../../utils/utils";
import { Organization } from "../../utils/types";
import useSWR, { useSWRConfig } from "swr";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { OrgRow } from "../../components/admin/OrgRow";
import { SearchBar } from "../../components/admin/SearchBar";
import { WebSocketStatusIndicator } from "../../components/admin/WebSocketStatusIndicator";
import { ScrapeAllDialog } from "../../components/admin/ScrapeAllDialog";
import { CsvUploadDialog } from "../../components/admin/CsvUploadDialog";
import AddEditModal from "../../components/admin/AddEditModal";
import type {
  OrgFormData,
  VOFormData,
} from "../../components/admin/AddEditModal";
import { FilterBar, Filters } from "../../components/admin/FilterBar";
import { useAdmin } from "../../utils/hooks";
import { Stats } from "../../components/admin/Stats";
import { SortControls, SortOptions } from "../../components/admin/SortControls";
import {
  useMessages,
  useOrgMessages,
  wsMessagesAtom,
} from "../../components/admin/WebSocketProvider";
import { useAtom } from "jotai";

// Constants
const PAGE_SIZE = 100;
const DEFAULT_DISPLAY_SIZE = 100;

const getInitialData = (type: "org" | "vo", id: string, org: Organization) => {
  if (type === "org") {
    return {
      org_name: org.org_name,
      domain_url: org.domain_url,
      org_causes: org.org_causes,
      org_desc: org.org_desc,
      logo_url: org.logo_url,
    };
  } else {
    const vo = org.vos.find((v) => v.id === id);
    if (!vo) return null;
    return {
      vo_title: vo.vo_title,
      vo_description: vo.vo_description,
      vo_min_age: vo.vo_min_age,
      vo_skills: vo.vo_skills,
      vo_url: vo.vo_url,
      locations: vo.locations.map((loc) => ({
        street_address: loc.street_address,
        city: loc.city,
        zip_code: loc.zip_code,
        state: loc.state,
        county: loc.county,
      })),
    };
  }
};

const useSelection = (initialState: Set<string> = new Set()) => {
  const [selectedOrgs, setSelectedOrgs] = useState<Set<string>>(initialState);

  const handleSelectOrg = useCallback((id: string, selected: boolean) => {
    setSelectedOrgs((prev) => {
      const next = new Set(prev);
      if (selected) {
        next.add(id);
      } else {
        next.delete(id);
      }
      return next;
    });
  }, []);

  const handleSelectAll = useCallback(
    (orgs: Organization[], selected: boolean) => {
      setSelectedOrgs(new Set(selected ? orgs.map((org) => org.id) : []));
    },
    []
  );

  const clearSelection = useCallback(() => {
    setSelectedOrgs(new Set());
  }, []);

  return {
    selectedOrgs,
    handleSelectOrg,
    handleSelectAll,
    clearSelection,
  };
};

const Manage = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    displaySize: DEFAULT_DISPLAY_SIZE,
  });

  const [modalState, setModalState] = useState({
    open: false,
    type: "org" as "org" | "vo",
    editId: undefined as string | undefined,
    editOrgId: undefined as string | undefined,
    editOrgName: undefined as string | undefined,
    data: null as OrgFormData | VOFormData | null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [scrapeState, setScrapeState] = useState({
    jobId: null as string | null,
    confirmOpen: false,
  });
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);

  const { mutate } = useSWRConfig();

  const [filters, setFilters] = useState<Filters>({
    state: null,
    failedScrape: false,
    startDate: null,
    endDate: null,
  });

  const [sort, setSort] = useState<SortOptions>({
    sortBy: "org_name",
    sortOrder: "asc",
  });

  const { selectedOrgs, handleSelectOrg, handleSelectAll, clearSelection } =
    useSelection();

  const selectedCount = useMemo(() => selectedOrgs.size, [selectedOrgs]);

  const { enqueueSnackbar } = useSnackbar();

  // Simplified handlers
  const handleClearCache = () =>
    mutate(() => true, undefined, { revalidate: true });

  const handleScrape = async (type: "all" | "selected") => {
    try {
      if (type === "all") {
        await fetcher<{ body: null }, { message: string }>(
          "POST",
          "/scrape/all",
          null,
          process.env.REACT_APP_SCRAPE_API_URL
        );
      } else {
        await fetcher<{ body: { org_ids: string[] } }, { message: string }>(
          "POST",
          "/scrape/selected",
          { org_ids: Array.from(selectedOrgs) },
          process.env.REACT_APP_SCRAPE_API_URL
        );
      }
      setScrapeState((prev) => ({ ...prev, jobId: type }));
    } catch (error) {
      console.error(`Failed to start scrape ${type}:`, error);
    }
  };

  // Simplified pagination calculation
  const { data, isLoading } = useAdmin({
    type: "orgs",
    pagination: { page: pagination.page, pageSize: PAGE_SIZE },
    searchQuery,
    filters,
    sort,
  });

  const displayOrgs = data?.data.data.slice(0, pagination.displaySize) ?? [];
  const total = data?.data.total ?? 0;

  // Memoize handlers
  const handleEdit = useCallback(
    (type: "org" | "vo", id: string, orgId: string, orgName: string) => {
      const org = displayOrgs.find((o: Organization) =>
        type === "org" ? o.id === id : o.id === orgId
      );
      if (!org) return;

      const initialData = getInitialData(type, id, org);
      if (!initialData) return;

      setModalState({
        open: true,
        type,
        editId: id,
        editOrgId: orgId,
        editOrgName: orgName,
        data: initialData as OrgFormData | VOFormData,
      });
    },
    [displayOrgs]
  );

  const handleAdd = useCallback(
    (type: "org" | "vo", orgId?: string, orgName?: string) => {
      setModalState({
        open: true,
        type,
        editId: undefined,
        editOrgId: orgId,
        editOrgName: orgName,
        data: null,
      });
    },
    []
  );

  const handleSelectAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleSelectAll(displayOrgs, event.target.checked);
    },
    [displayOrgs, handleSelectAll]
  );

  // Clear selections when filters change
  useEffect(() => {
    clearSelection();
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [filters, searchQuery, clearSelection]);

  // Simplified search with debounce
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
      setPagination((prev) => ({ ...prev, page: 1 }));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Memoize the table body
  const tableBody = useMemo(() => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={9} sx={{ border: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 3,
              }}
            >
              <CircularProgress size={40} />
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    return displayOrgs.map((org: Organization) => (
      <OrgRow
        key={org.id}
        org={org}
        onEdit={handleEdit}
        onAdd={handleAdd}
        scrapeAllJobId={scrapeState.jobId}
        selected={selectedOrgs.has(org.id)}
        onSelect={handleSelectOrg}
      />
    ));
  }, [
    displayOrgs,
    handleEdit,
    handleAdd,
    scrapeState.jobId,
    selectedOrgs,
    handleSelectOrg,
  ]);

  // Optimize select all checkbox by calculating these values once
  const hasOrgs = useMemo(() => displayOrgs.length > 0, [displayOrgs]);
  const allSelected = useMemo(
    () => hasOrgs && selectedCount === displayOrgs.length,
    [hasOrgs, selectedCount, displayOrgs.length]
  );
  const someSelected = useMemo(
    () => selectedCount > 0 && selectedCount < displayOrgs.length,
    [selectedCount, displayOrgs.length]
  );

  // Memoize the select all checkbox with fewer dependencies
  const selectAllCheckbox = useMemo(
    () => (
      <Checkbox
        indeterminate={someSelected}
        checked={allSelected}
        onChange={handleSelectAllChange}
      />
    ),
    [someSelected, allSelected, handleSelectAllChange]
  );

  // Memoize pagination controls
  const paginationControls = useMemo(
    () => (
      <TablePagination
        component="div"
        count={total}
        page={pagination.page - 1}
        onPageChange={(_, newPage) =>
          setPagination((prev) => ({ ...prev, page: newPage + 1 }))
        }
        rowsPerPage={pagination.displaySize}
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={(e) => {
          const newSize = parseInt(e.target.value, 10);
          setPagination({ page: 1, displaySize: newSize });
        }}
      />
    ),
    [total, pagination.page, pagination.displaySize]
  );

  // Add effect to listen for completion messages from the WebSocket provider
  const [messages, setMessages] = useMessages(); // Special key for global messages
  useEffect(() => {
    if (messages["__all__"]?.length) {
      const lastMessage = messages["__all__"][messages["__all__"]?.length - 1];
      if (
        lastMessage?.type === "job_complete" &&
        typeof lastMessage.success_count === "number" &&
        typeof lastMessage.failure_count === "number"
      ) {
        const key: SnackbarKey = enqueueSnackbar(
          `Scrape completed: ${lastMessage.success_count} successful, ${lastMessage.failure_count} failed`,
          {
            variant: "success",
            persist: true,
            SnackbarProps: {
              onClick: () => {
                closeSnackbar(key);
                setMessages((prev) => {
                  const newMessages = { ...prev };
                  delete newMessages["__all__"];
                  return newMessages;
                });
              },
            },
          }
        );
      }
    }
  }, [messages, enqueueSnackbar]);

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h5">Manage Organizations</Typography>
          <WebSocketStatusIndicator />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="outlined" color="primary" onClick={handleClearCache}>
            Refresh Data
          </Button>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleScrape("selected")}
              startIcon={<RefreshIcon />}
              disabled={isLoading}
            >
              Rescrape Selected ({selectedCount})
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAdd("org")}
          >
            Add New Organization
          </Button>
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
            <SearchBar onSearch={debouncedSearch} />
            <FilterBar filters={filters} onFiltersChange={setFilters} />
          </Box>
          <SortControls sort={sort} onSortChange={setSort} />
        </Box>
      </Box>
      <Stats
        filter={{
          type: "orgs",
          pagination: { page: pagination.page, pageSize: PAGE_SIZE },
          searchQuery,
          filters,
          sort,
        }}
      />
      <TableContainer component={Paper}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {paginationControls}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox">{selectAllCheckbox}</TableCell>
              <TableCell />
              <TableCell>Organization</TableCell>
              <TableCell>Causes</TableCell>
              <TableCell>VOs</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
        <Box sx={{ borderTop: 1, borderColor: "divider" }}>
          {paginationControls}
        </Box>
      </TableContainer>

      <AddEditModal
        open={modalState.open}
        onClose={() => setModalState((prev) => ({ ...prev, open: false }))}
        type={modalState.type}
        id={modalState.editId}
        orgId={modalState.editOrgId}
        orgName={modalState.editOrgName}
        initialData={modalState.data ?? undefined}
      />

      <ScrapeAllDialog
        open={scrapeState.confirmOpen}
        onClose={() =>
          setScrapeState((prev) => ({ ...prev, confirmOpen: false }))
        }
        onConfirm={() => handleScrape("all")}
      />

      <CsvUploadDialog
        open={csvUploadOpen}
        onClose={() => setCsvUploadOpen(false)}
      />
    </Box>
  );
};

export default Manage;
