import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useMe } from "../hooks/useResults";


const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { data: me, isLoading, error } = useMe();
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    if (isLoading) return;
    if (!me || error) {
      setIsAllowed(false);
    } else {
      setIsAllowed(me.role === "admin");
    }
  }, [me, isLoading]);

  if (isAllowed === null) {
    return <div>Loading...</div>;
  }

  if (!isAllowed) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
