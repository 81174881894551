import React, { useState } from "react";
import { Button, Dialog, DialogTitle } from "@mui/material";
import { ScrapeProgress } from "./ScrapeProgress";
import { fetcher } from "../../utils/fetchData";
import RefreshIcon from "@mui/icons-material/Refresh";

interface ScrapeButtonProps {
  orgId: string;
  size?: "small" | "medium" | "large";
}

export const ScrapeButton: React.FC<ScrapeButtonProps> = ({
  orgId,
  size = "small",
}) => {
  const handleScrape = async () => {
    try {
      await fetcher<never, { job_id: string }>(
        "POST",
        `/scrape/org/${orgId}`,
        undefined,
        process.env.REACT_APP_SCRAPE_API_URL
      );
    } catch (error) {
      console.error("Failed to start scrape:", error);
    }
  };

  return (
    <Button
      size={size}
      startIcon={<RefreshIcon />}
      onClick={handleScrape}
      color="primary"
    >
      Rescrape
    </Button>
  );
};
