import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { useSWRConfig } from "swr";
import {
  WSLogMessage,
  Organization,
  WSLogMessageComplete,
} from "../../utils/types";
import { DatabaseVO } from "../../utils/types";
import { useSession } from "@supabase/auth-helpers-react";

export type WebSocketStatus = "connecting" | "connected" | "disconnected";

// Create atoms for WebSocket state
export const wsMessagesAtom = atom<{ [key: string]: WSLogMessage[] }>({});
export const wsConnectionAtom = atom<WebSocket | null>(null);
export const wsStatusAtom = atom<WebSocketStatus>("disconnected");

// Module-level WebSocket manager singleton
class WebSocketManager {
  private static instance: WebSocketManager | null = null;
  private ws: WebSocket | null = null;
  private reconnectTimeout: NodeJS.Timeout | null = null;
  private connectingTimeout: NodeJS.Timeout | null = null;
  private pingTimeout: NodeJS.Timeout | null = null;
  private pingInterval: NodeJS.Timer | null = null;
  private currentStatus: WebSocketStatus = "disconnected";
  private currentAccessToken: string | null = null;
  private isPageVisible: boolean = true;
  private connectionId: number = 0;
  private activeConnectionId: number | null = null;
  private lastPongTime: number = 0;
  private readonly CONNECTING_TIMEOUT = 5000; // 5 seconds timeout for connecting state
  private readonly RECONNECT_DELAY = 5000;
  private readonly PING_INTERVAL = 30000; // 30 seconds
  private readonly PONG_TIMEOUT = 10000; // 10 seconds to receive pong before considering connection dead
  private stateSetters: {
    setStatus: ((status: WebSocketStatus) => void) | null;
    setWsConnection: ((ws: WebSocket | null) => void) | null;
    setMessages: ((messages: any) => void) | null;
    updateOrgInCache: ((newData: Organization, voChanges: any) => void) | null;
  } = {
    setStatus: null,
    setWsConnection: null,
    setMessages: null,
    updateOrgInCache: null,
  };

  private constructor() {
    if (typeof document !== "undefined") {
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
      window.addEventListener("beforeunload", () => this.cleanup());
    }
  }

  static getInstance(): WebSocketManager {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager();
    }
    return WebSocketManager.instance;
  }

  private startPingInterval(connectionId: number) {
    this.clearPingTimeout();

    const pingMessage = JSON.stringify({ type: "ping" });
    const sendPing = () => {
      if (
        this.ws &&
        this.ws.readyState === WebSocket.OPEN &&
        this.activeConnectionId === connectionId
      ) {
        try {
          this.ws.send(pingMessage);

          // Set timeout for pong response
          this.pingTimeout = setTimeout(() => {
            const timeSinceLastPong = Date.now() - this.lastPongTime;
            if (timeSinceLastPong > this.PONG_TIMEOUT) {
              console.debug("Ping timeout - no pong received");
              this.cleanup();
            }
          }, this.PONG_TIMEOUT);
        } catch (e) {
          console.warn("Error sending ping:", e);
          this.cleanup();
        }
      }
    };

    // Start ping interval
    sendPing(); // Send first ping immediately
    const intervalId = setInterval(() => {
      if (this.activeConnectionId === connectionId) {
        sendPing();
      } else {
        clearInterval(intervalId);
      }
    }, this.PING_INTERVAL);

    // Store interval for cleanup
    this.pingInterval = intervalId;
  }

  private clearPingTimeout() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
      this.pingTimeout = null;
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  private handleVisibilityChange = () => {
    const wasVisible = this.isPageVisible;
    this.isPageVisible = document.visibilityState === "visible";

    if (this.isPageVisible && !wasVisible) {
      // Page became visible - verify connection health
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        const timeSinceLastPong = Date.now() - this.lastPongTime;
        if (timeSinceLastPong > this.PONG_TIMEOUT) {
          console.debug("Connection stale after visibility change");
          this.cleanup();
        }
      }

      if (!this.isConnectionValid() && this.currentAccessToken) {
        this.cleanup();
        this.setupWebSocket(this.currentAccessToken);
      }
    } else if (!this.isPageVisible && wasVisible) {
      this.clearTimeouts();
    }
  };

  private isConnectionValid(): boolean {
    if (!this.ws || !this.activeConnectionId) return false;
    try {
      // Only consider OPEN connections as valid
      return this.ws.readyState === WebSocket.OPEN;
    } catch (e) {
      console.warn("Error checking WebSocket state:", e);
      return false;
    }
  }

  private terminateConnection(ws: WebSocket) {
    try {
      // Remove all listeners first
      ws.onclose = null;
      ws.onerror = null;
      ws.onmessage = null;
      ws.onopen = null;

      // Force close the connection
      if (ws.readyState !== WebSocket.CLOSED) {
        ws.close();
      }
    } catch (e) {
      console.warn("Error terminating WebSocket:", e);
    }
  }

  private updateStatus(status: WebSocketStatus) {
    this.currentStatus = status;
    this.stateSetters.setStatus?.(status);
  }

  private updateConnection(ws: WebSocket | null) {
    this.ws = ws;
    this.stateSetters.setWsConnection?.(ws);
  }

  getCurrentStatus(): WebSocketStatus {
    return this.currentStatus;
  }

  initialize(
    setStatus: (status: WebSocketStatus) => void,
    setWsConnection: (ws: WebSocket | null) => void,
    setMessages: (messages: any) => void,
    updateOrgInCache: (newData: Organization, voChanges: any) => void
  ) {
    this.stateSetters = {
      setStatus,
      setWsConnection,
      setMessages,
      updateOrgInCache,
    };

    // Synchronize current state with new setters
    setStatus(this.currentStatus);
    setWsConnection(this.ws);
  }

  private clearTimeouts() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    if (this.connectingTimeout) {
      clearTimeout(this.connectingTimeout);
      this.connectingTimeout = null;
    }
    this.clearPingTimeout();
  }

  setupWebSocket(accessToken: string) {
    // Don't create new connection if we're already connected
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      const timeSinceLastPong = Date.now() - this.lastPongTime;
      if (timeSinceLastPong <= this.PONG_TIMEOUT) {
        return;
      }
      // Connection is stale, clean it up
      console.debug("Existing connection is stale");
    }

    // Clean up any existing connection and timeouts
    this.cleanup();

    // Don't establish new connections when page is not visible
    if (!this.isPageVisible) {
      this.updateStatus("disconnected");
      return;
    }

    this.currentAccessToken = accessToken;
    const connectionId = ++this.connectionId;

    this.updateStatus("connecting");
    const newWs = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws`, [
      "json",
      accessToken,
    ]);
    this.activeConnectionId = connectionId;
    this.updateConnection(newWs);

    // Set timeout for connecting state
    this.connectingTimeout = setTimeout(() => {
      if (
        this.currentStatus === "connecting" &&
        this.activeConnectionId === connectionId
      ) {
        console.debug("WebSocket connection timeout:", connectionId);
        this.cleanup();
      }
    }, this.CONNECTING_TIMEOUT);

    newWs.onopen = () => {
      if (this.activeConnectionId === connectionId) {
        this.clearTimeouts();
        this.updateStatus("connected");
        this.lastPongTime = Date.now(); // Initialize last pong time
        this.startPingInterval(connectionId);
        console.debug("WebSocket connected:", connectionId);
      } else {
        this.terminateConnection(newWs);
      }
    };

    newWs.onclose = () => {
      if (this.activeConnectionId === connectionId) {
        console.debug("WebSocket closed:", connectionId);
        this.clearTimeouts();
        this.updateStatus("disconnected");
        this.updateConnection(null);
        this.activeConnectionId = null;

        // Only attempt reconnection if the page is visible
        if (this.isPageVisible) {
          this.reconnectTimeout = setTimeout(() => {
            this.reconnectTimeout = null;
            if (this.currentStatus === "disconnected" && this.isPageVisible) {
              this.setupWebSocket(this.currentAccessToken!);
            }
          }, this.RECONNECT_DELAY);
        }
      }
    };

    newWs.onmessage = (event) => {
      if (this.activeConnectionId !== connectionId) return;

      const message = JSON.parse(event.data);

      // Handle pong messages
      if (message.type === "pong") {
        this.lastPongTime = Date.now();
        if (this.pingTimeout) {
          clearTimeout(this.pingTimeout);
          this.pingTimeout = null;
        }
        return;
      }

      // Handle regular messages
      if (message.org_id) {
        const orgName = message.org_name as string;
        this.stateSetters.setMessages?.((prev: any) => ({
          ...prev,
          [orgName]: [...(prev[orgName] || []), message],
        }));

        if (message.type === "org_complete" && message.new_data) {
          (async () => {
            try {
              if (message.success && this.stateSetters.updateOrgInCache) {
                await this.stateSetters.updateOrgInCache(
                  message.new_data!,
                  message.vo_changes
                );
              }
            } catch (error) {
              console.error("Error updating org in cache:", error);
            }
          })();
        }
      }

      // TODO: Handle global messages by adding a new key to the messages object
      if (message.type === "job_complete") {
        this.stateSetters.setMessages?.((prev: any) => {
          const newMessages = {
            ...prev,
            __all__: [...(prev["__all__"] || []), message],
          };
          return newMessages;
        });
      }
    };

    newWs.onerror = () => {
      if (this.activeConnectionId === connectionId) {
        console.debug("WebSocket error:", connectionId);
        this.cleanup();
      } else {
        this.terminateConnection(newWs);
      }
    };
  }

  cleanup() {
    this.clearTimeouts();

    if (this.ws) {
      const ws = this.ws;
      this.ws = null;
      this.activeConnectionId = null;
      this.lastPongTime = 0;
      this.terminateConnection(ws);
    }

    this.updateStatus("disconnected");
  }

  destroy() {
    this.cleanup();
    if (typeof document !== "undefined") {
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }
    WebSocketManager.instance = null;
  }
}

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [, setMessages] = useAtom(wsMessagesAtom);
  const [, setWsConnection] = useAtom(wsConnectionAtom);
  const [, setStatus] = useAtom(wsStatusAtom);
  const { mutate } = useSWRConfig();
  const session = useSession();

  const updateOrgInCache = async (
    new_data: Organization,
    vo_changes: WSLogMessageComplete["vo_changes"]
  ) => {
    await mutate(
      (key: any) => key[0] === "/admin" && key[1].type === "orgs",
      (current: any) => {
        if (!current?.data?.data) return current;

        const updatedData = {
          ...current,
          data: {
            ...current.data,
            data: current.data.data.map((org: any) => {
              if (org.id === new_data.id) {
                return {
                  ...new_data,
                  vos: [
                    ...new_data.vos,
                    ...org.vos.filter(
                      (v: DatabaseVO) =>
                        !vo_changes?.removed
                          .map((r) => r.vo_title)
                          .includes(v.vo_title)
                    ),
                  ],
                };
              }
              return org;
            }),
          },
        };

        return updatedData;
      },
      {
        revalidate: false,
        populateCache: true,
      }
    );
  };

  useEffect(() => {
    const wsManager = WebSocketManager.getInstance();

    // Initialize with current setters
    wsManager.initialize(
      setStatus,
      setWsConnection,
      setMessages,
      updateOrgInCache
    );

    if (session?.access_token) {
      wsManager.setupWebSocket(session.access_token);
    }

    return () => {
      // Only destroy the manager when the provider is unmounted
      wsManager.destroy();
    };
  }, [setMessages, setWsConnection, setStatus, session]);

  return <div>{children}</div>;
};

// Hooks
export const useOrgMessages = (orgName: string) => {
  const [messages] = useAtom(wsMessagesAtom);
  return messages[orgName] || [];
};

export const useMessages = () => {
  return useAtom(wsMessagesAtom);
};

export const useWebSocketStatus = () => {
  const [status] = useAtom(wsStatusAtom);
  return status;
};
